import "./input.css";

const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
});

let channels, channel;

function setupTabs(channel) {
    document.querySelectorAll('.tab-button').forEach(button => {
        button.addEventListener('click', () => {
            document.querySelectorAll('.tab-content').forEach(content => {
                content.classList.add('hidden');
            });
            document.querySelectorAll(".tab-button").forEach(content => {
                content.classList.remove("outline");
            });
            const tabId = button.getAttribute('id');
            document.getElementById(tabId).classList.add("outline");
            document.getElementById(tabId.replace("-tab", "")).classList.remove('hidden');
        });
    });
    let tab = document.getElementById(channel.group+"-tab");
    tab.click();
}

window.createStreamLake = (channel) => {
    window.streamLake && window.streamLake.destroy();
    const videoEl = document.getElementById("video");
    window.streamLake = new StreamLake(`${getSocketAddress()}/ws/${channel.name}`, true, videoEl);
    window.streamLake.loadSource(getBaseUrl() + "/api/proxy/" + encodeURIComponent(channel.stream_url));
    history.pushState(null, "", "?id="+ channel.id);
    document.title = channel.name;
}

function addStreamButtons(channels) {
    channels.forEach(channel => {
        let tab = document.getElementById(channel.group);
        if (!tab) { return; }

        const channelStr = JSON.stringify(channel);
        const button = `<button id="${channel.id}" onclick='createStreamLake(${channelStr})' class="bg-zinc-800 p-2 rounded-lg shadow hover:bg-zinc-700 w-full">
                            <h4 class="text-sm font-bold">${channel.name}</h4>
                            <p class="text-zinc-300 text-sm">${channel.time}</p>
                        </button>`;

        tab.children[0].insertAdjacentHTML('beforeend', button);
    });
}

function getSocketAddress() {
    let socketProtocol = window.location.protocol === "https:" ? "wss" : "ws";
    if (window.location.hostname.includes("topseyir")) {
        let socketDomainArray = window.location.hostname.split(".");
        socketDomainArray.length === 3 && socketDomainArray.shift();
        return `${socketProtocol}://socket.${socketDomainArray.join(".")}`;
    }
    return `${socketProtocol}://${window.location.hostname}:8000`;
}

function getBaseUrl() {
    if (window.location.hostname.includes("topseyir")) {
        return "https://socket.topseyir.com";
    }
    return `${window.location.protocol}//${window.location.hostname}:8000`;
}

async function getChannels() {
    return await (await fetch(getBaseUrl() + "/api/get_channels")).json();
}

async function run(wasm) {
    const { StreamLake } = wasm;
    window.StreamLake = StreamLake;
    channels = await getChannels();
    addStreamButtons(channels);
    if (!params.id) {
        channel = channels[0];
        window.location.href = "?id=" + channel.id;
    } else {
        channel = channels.find(x => x.id === params.id)
        if (!channel) {
            channel = channels[0];
            window.location.href = "?id=" + channel.id;
        }
    }
    setupTabs(channel);
    
    createStreamLake(channel);
}

import("../pkg").then(run);